<template>
  <div>
    <!-- 头部 -->
    <slot name="head">
      <div style="background-color: #FFFFFF;width: 100%;">
        <div id="header-div" style="position:relative;display: flex; align-items: center;width: 1240px;margin: 0 auto;">
          <div @click="toIndex" style="display: flex; align-items: center;cursor: pointer">
<!--            <img style="width: 48px; height: 48px; " src="../assets/logo/logo2.png"/>-->
            <div style="margin-left: 10px;">
              <span style="font-size: 24px;">智慧模拟医学网</span>
            </div>
          </div>
          <div style="flex: 1"></div>
          <div style="display: flex;height: 74px">
            <div class="headTitle" @click="toLaboratory">虚拟实验室</div>
            <div class="headTitle" @click="toHealthyChinaInitiative">健康中国行</div>
            <div class="headTitle" @click="toSupportAndService">支持与服务</div>

          </div>

          <div style="flex: 1"></div>
          <div>
            <el-input placeholder="请输入内容" v-model="name">
              <el-button slot="append" icon="el-icon-search" type="primary" @click="searchCourse()"></el-button>
            </el-input>
          </div>
          <div v-if="!isLoginFlag" class="headTitle" style="color: var(--primary-color);margin-left: 20px;"
               @click="toLogin">
            登录 | 注册
          </div>
          <div style="display: flex; align-items: center; cursor: pointer;" @click="toggleDrawer">

            <!-- 已登录显示 -->
            <img v-show="my.profile != null && isLoginFlag"
                 style="width: 30px; height: 30px;margin-left: 24px; object-fit: cover; border-radius: 50%;"
                 :src="my.profile"/>

            <!-- 未登录显示 -->
            <img v-show="my.profile == null && isLoginFlag"
                 style="width: 30px; height: 30px;margin-left: 24px; object-fit: cover; border-radius: 50%;"
                 src="../assets/myCenter/headPortrait.png"/>
            <span style="margin-left: 6px; font-size: 14px;">{{ my.name }}</span>


          </div>

          <!-- 退出登录确认对话框 -->
          <el-dialog title="提示" :visible.sync="logoutDialogVisible" width="30%">
            <span>您确定要退出登录吗？</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="logoutDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="confirmLogout">确 定</el-button>
            </span>
          </el-dialog>
          <div v-if="drawerVisible"
               style="position: absolute; top:74px; right: -40px; background-color: white; width: 146px;height: 148px; box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); z-index: 999;">
            <div>
              <div style="width: 146px;height: 44px;margin-top: 4px;display: flex; align-items: center;cursor: pointer;"
                   @click="goToPersonalCenter" @mouseover="hoverPersonalCenter(true)"
                   @mouseout="hoverPersonalCenter(false)" :style="personalCenterHoverStyle">
                <img style="width: 18px;height: 18px;margin-left: 16px;"
                     src="../assets/index/user-line.png">
                <span style="margin-left: 8px;font-family: PingFang SC;">个人中心</span>
              </div>
              <div style="width: 146px;height: 44px;margin-top: 4px;display: flex; align-items: center;cursor: pointer;"
                   @click="openMessageCenter" @mouseover="hoverMessageCenter(true)"
                   @mouseout="hoverMessageCenter(false)" :style="messageCenterHoverStyle">
                <img style="width: 18px;height: 18px;margin-left: 16px;"
                     src="../assets/index/message.png">
                <span style="margin-left: 8px;font-family: PingFang SC;">消息中心</span>
              </div>
              <div style="width: 146px;height: 44px;margin-top: 4px;display: flex; align-items: center;cursor: pointer;"
                   @click="logout" @mouseover="hoverLogout(true)" @mouseout="hoverLogout(false)"
                   :style="logoutHoverStyle">
                <img style="width: 18px;height: 18px;margin-left: 16px;"
                     src="../assets/index/Vector.png">
                <span
                    style="margin-left: 8px;font-family: PingFang SC;color: rgba(244, 99, 99, 1);">退出登录</span>
              </div>
            </div>
          </div>
          <!--                        <div style=" height: 30px;  display: flex; align-items: flex-start;">-->
          <!--                            <div style="display: flex; align-items: center;">-->
          <!--                                <img style="width: 30px; height: 30px;cursor: pointer"-->
          <!--                                    src="../assets/index/virtualLaboratory.png" @click="toLaboratory" />-->
          <!--                                <span class="headTitle" style="margin-left: 6px; align-self: center;font-size: 14px;cursor: pointer"-->
          <!--                                    @click="toLaboratory">虚拟实验室</span>-->
          <!--                            </div>-->

          <!--                            <div style="display: flex; align-items: center;">-->
          <!--                                <img style="width: 30px; height: 30px;margin-left: 24px;cursor: pointer"-->
          <!--                                    src="../assets/index/healthyBody.png" @click="toHealthyChinaInitiative" />-->
          <!--                                <span style="margin-left: 6px; align-self: center;font-size: 14px;cursor: pointer"-->
          <!--                                    @click="toHealthyChinaInitiative">健康中国行</span>-->
          <!--                            </div>-->

          <!--                            <div style="display: flex; align-items: center;">-->
          <!--                                <img style="width: 30px; height: 30px;margin-left: 24px;cursor: pointer"-->
          <!--                                    src="../assets/index/InterfaceDocking.png" @click="toSupportAndService" />-->
          <!--                                <span style="margin-left: 6px; align-self: center;font-size: 14px;cursor: pointer"-->
          <!--                                    @click="toSupportAndService">支持与服务</span>-->
          <!--                            </div>-->
          <!--                          <div style="display: flex; width: 270px;margin-left: 46px;height: 36px;">-->
          <!--                            <input v-model="name" class="headInput" type="text" placeholder="请输入关键词查询">-->
          <!--                            <div style="flex: 1;"></div>-->
          <!--                            <button-->
          <!--                                style="width: 30%; background-color: #377BFE; border: none; color: white; border-radius: 0 5px 5px 0; transition: all 0.3s ease;cursor: pointer"-->
          <!--                                @click="searchCourse()">搜索</button>-->
          <!--                          </div>-->
          <!--                            <div v-if="!isLoginFlag" style="display: flex; align-items: center;">-->
          <!--                                <img style="width: 30px; height: 30px;margin-left: 24px;cursor: pointer"-->
          <!--                                    src="../assets/index/loginandregister.png" @click="toLogin" />-->
          <!--                                <span style="margin-left: 6px; align-self: center;font-size: 14px;cursor: pointer"-->
          <!--                                    @click="toLogin">登录/注册</span>-->
          <!--                            </div>-->


          <!--                            <div style="display: flex; align-items: center; cursor: pointer;" @click="toggleDrawer">-->

          <!--                                &lt;!&ndash; 已登录显示 &ndash;&gt;-->
          <!--                                <img v-show="my.profile != null && isLoginFlag"-->
          <!--                                    style="width: 30px; height: 30px;margin-left: 24px; object-fit: cover; border-radius: 50%;"-->
          <!--                                    :src="my.profile" />-->

          <!--                                &lt;!&ndash; 未登录显示 &ndash;&gt;-->
          <!--                                <img v-show="my.profile == null && isLoginFlag"-->
          <!--                                    style="width: 30px; height: 30px;margin-left: 24px; object-fit: cover; border-radius: 50%;"-->
          <!--                                    src="../assets/myCenter/headPortrait.png" />-->
          <!--                                <span style="margin-left: 6px; font-size: 14px;">{{ my.name }}</span>-->


          <!--                            </div>-->

          <!--                            &lt;!&ndash; 退出登录确认对话框 &ndash;&gt;-->
          <!--                            <el-dialog title="提示" :visible.sync="logoutDialogVisible" width="30%">-->
          <!--                                <span>您确定要退出登录吗？</span>-->
          <!--                                <span slot="footer" class="dialog-footer">-->
          <!--                                    <el-button @click="logoutDialogVisible = false">取 消</el-button>-->
          <!--                                    <el-button type="primary" @click="confirmLogout">确 定</el-button>-->
          <!--                                </span>-->
          <!--                            </el-dialog>-->

          <!--                        </div>-->


        </div>
      </div>

    </slot>
  </div>
</template>

<script>
import {myCenterApi} from '@/api/myCenter/myCenter'

export default {
  data() {
    return {
      // 控制退出登录对话框的显示
      logoutDialogVisible: false,
      name: '',
      isLoginFlag: false,
      drawerVisible: false,
      isPersonalCenterHovering: false,
      isMessageCenterHovering: false,
      isLogoutHovering: false,
      my: {
        approveIdCard: "",
        approveTeacher: "",
        name: "",
        gender: "",
        mobile: "",
        profile: "",
        orgId: "",
        professionType: "",
        professionTypeId: "",
        idCardNo: "",
        userId: ""

      }
    };
  },

  methods: {
    toLogin() {
      this.$router.push("/login")
    },
    mycenter() {
      myCenterApi().then(resp => {
        if(resp.data.code===0){
        this.my = resp.data.data
        }
      })
    },
    isLogin() {
      var token = localStorage.getItem("token")
      if (token == null || token == "") {
        this.isLoginFlag = false
      } else {
        this.isLoginFlag = true
      }
    },
    //去虚拟实验室
    toLaboratory() {
      this.$router.push("/laboratory")
    },

    //支持与服务
    toSupportAndService() {
      this.$router.push("/supportAndService")
    },

    //去健康中国行
    toHealthyChinaInitiative() {
      this.$router.push("/healthyChinaInitiative")
    },

    //搜索
    searchCourse() {
      this.$router.push("/course?name=" + this.name)
      // courseListApi(
      //     1,
      //     this.name,
      // ).then(resp => {
      //     console.log(resp)
      //     this.courses = resp.data.data.content
      //     this.totalElements = resp.data.data.totalElements
      // })
    },
    toggleDrawer() {
      this.drawerVisible = !this.drawerVisible;
    },
    goToPersonalCenter() {
      // 跳转到个人中心的逻辑
      this.$router.push("/myCenter/myCenter")
    },
    openMessageCenter() {
      // 打开消息中心的逻辑
      this.$router.push("/messageCenter/msgCenter")
    },
    // 显示退出登录确认对话框
    logout() {
      // 退出登录的逻辑
      this.logoutDialogVisible = true
    },
    handleClickOutside(event) {
      if (this.$el.contains(event.target) === false && this.drawerVisible) {
        this.drawerVisible = false;
      }
    },
    hoverPersonalCenter(isHover) {
      this.isPersonalCenterHovering = isHover;
    },
    hoverMessageCenter(isHover) {
      this.isMessageCenterHovering = isHover;
    },
    hoverLogout(isHover) {
      this.isLogoutHovering = isHover;
    },
    getPersonalCenterHoverStyle() {
      if (this.isPersonalCenterHovering) {
        return 'background-color: rgba(249, 249, 249, 1);';
      } else {
        return '';
      }
    },
    getMessageCenterHoverStyle() {
      if (this.isMessageCenterHovering) {
        return 'background-color: rgba(249, 249, 249, 1);';
      } else {
        return '';
      }
    },
    getLogoutHoverStyle() {
      if (this.isLogoutHovering) {
        return 'background-color: rgba(249, 249, 249, 1);';
      } else {
        return '';
      }
    },
    //返回首页
    toIndex() {
      this.$router.push("/index")
    },

    // 确认退出登录
    confirmLogout() {
      localStorage.clear("token")
      // 假设已经成功退出登录
      this.$message({
        type: 'success',
        message: '退出登录成功！',
      });

      this.$router.push('/login'); // 跳转到登录页面

      // 关闭对话框
      this.logoutDialogVisible = false;
    },

  },


  mounted() {
    this.mycenter()
    this.isLogin()
    document.addEventListener('click', this.handleClickOutside);
  },

  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  },

  computed: {
    personalCenterHoverStyle() {
      return this.getPersonalCenterHoverStyle();
    },
    messageCenterHoverStyle() {
      return this.getMessageCenterHoverStyle();
    },
    logoutHoverStyle() {
      return this.getLogoutHoverStyle();
    }
  }
};
</script>

<style scoped>
#header-div {
  width: 83%;
  height: 74px;
  margin: auto;
}

/* 自定义 Dialog 的标题样式 */
.el-dialog__title {
  font-size: 18px;
  /* 增大标题字体大小 */
  font-weight: bold;
  /* 加粗标题 */
  color: #333;
  /* 更改标题颜色 */
  padding: 20px 20px;
  /* 调整标题内边距 */
}

/* 自定义 Dialog 的内容样式 */
.el-dialog.el-dialog__body {
  padding: 20px;
  /* 调整内容内边距 */
  font-size: 14px;
  /* 设置内容字体大小 */
  color: #666;
  /* 设置内容文字颜色 */
  text-align: center;
  /* 将内容居中对齐（如果需要） */
}

/* 自定义 Dialog 的底部按钮样式 */
.el-dialog__footer {
  text-align: right;
  /* 将按钮组右对齐 */
  padding: 10px 20px;
  /* 调整底部内边距 */
  border-top: none;
  /* 去掉顶部边框 */
}


/* 如果你想要一个特别的“确定”按钮样式 */
.el-button--primary {
  background-color: #409EFF;
  /* 更改主按钮的背景颜色 */
  border-color: #409EFF;
  /* 更改主按钮的边框颜色 */
}

/* 如果你想要自定义取消按钮的样式（虽然在这个例子中没有直接应用，但可以作为参考） */
.el-button--default:hover {
  background-color: #f5f5f5;
  /* 更改默认按钮的悬停背景颜色 */
}

.headInput {
  width: 70%;
  padding: 5px;
  border: 2px solid rgb(55, 123, 254);
  border-radius: 5px 0 0 5px;
  margin-left: 15px;
  outline: none;
}

.headTitle {
  height: 100%;
  font-size: 18px;
  padding: 0 20px;
  line-height: 74px;
  cursor: pointer;
  user-select: none;
}

.headTitle:hover {
  background-color: var(--body-bg-color);
}

.headInput:hover {
  border-color: rgb(55, 123, 254);
}

.el-input-group__append, .el-input-group__prepend {
  border-radius: 0 30px 30px 0 !important;
  background: var(--primary-color) !important;
  color: white !important;
  border: none !important;
}


.el-input__inner {
  border-radius: 30px 0 0 30px !important;
  border-color: var(--primary-color) !important;
}

@media screen and (max-width: 768px) {
  #header-div span {
    font-size: 16px;
  }

  .headInput {
    width: 60%;
  }

  .input-group button {
    font-size: 14px;
  }

  /* 调整其他元素的样式 */
}
/deep/.el-input-group>.el-input__inner{
  border-radius:20px 0 0 20px  ;
}
/deep/.el-input-group__append, .el-input-group__prepend{
  border-radius:0 20px  20px   0 ;
}
</style>